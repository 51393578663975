

html, body, #root, .App {
  width: 100%;
  height: 100%;
  margin: 0px;
}
body{
  font-family: "Interstate Regular", Helvetica, Arial, sans-serif;
  font-size: 16px;
  color:#282828;
  margin: 0;
  -webkit-overflow-scrolling: touch; /* Lets it scroll lazy */
}
.prime-header{
  height: 50px;
  background: #212121;
  position: relative;
  padding: 0 20px;
  margin-bottom: 10px;
}
.prime-header .logo{width:162px; height: 55px; position: absolute; top:12px; left:20px;}

.prime-footer{
  background:#000000;
  padding: 20px 0 20px 0;
}
.prime-footer .ftInfo{
  width:152px; 
  height: 41px;
  background: url(/ft-info.jpg) no-repeat;
  background-size: 100%;
  margin: auto;
  display: block;
}



#viewer{
  height: calc(100vh - 421px) !important;
  min-height: 500px;
  margin: 0 auto 10px auto;
  width: 70% !important;
}

.grd-container{
  background: -webkit-gradient(linear, left top, right top, from(#de1245), to(#ffbc15));
  background: linear-gradient(to right, #de1245 0%, #ffbc15 100%);
  width:100%;
  display: inline-block;
  text-align: center;
  padding: 20px 0;
  margin-bottom: 10px;
}
.checkbox{
  color:#fff;
}

.sign-wrapper{
  /* width:60%; */
  width:300px;
  background: #f4f4f4;
  height: 190px;
  margin: 0 auto 10px auto;
  position: relative;
}
.sign-wrapper .header{
  height: 45px;
  line-height: 45px;
  color:#fff;
  font-weight: bold;
  display: inline-block;
  width:100%;
  background: #000;
  text-align: center;
}
.sign-wrapper .canvas{
  position: absolute;
  top: 100%;
  left: 0;
  top: 40px;
  /* width: 100%; */
  /* height: 145px; */
  /* background: pink; */
}
.sign-wrapper .disabled-overlay{
  position: absolute;
  top:0;
  bottom: 0;
  left: 0;
  right: 0;
  width:100%;
  height: 100%;
  background:rgba(204, 204, 204, .8);
  cursor: not-allowed;
}
.button-holder{
  text-align:center;
  margin-bottom: 10px;
}

button, input[type=button], .button{
  background: #000;
  border: none;
  color:#fff;
  padding:10px 20px;
  font-size: 20px;
}
button.disabled, input[type=button]:disabled {
  color: #fff;
  /* background-color: #6c757d;
  border-color: #6c757d; */
  background: rgba(204, 204, 204, .8);
  opacity: .65;
  cursor: not-allowed;
}

@media only screen and (max-width:768px){
  .prime-header{
      margin-bottom: 30px;
  }
  .container{width:95%;}
  #viewer {width:95% !important;}
  .sign-wrapper{
      /* width:95% */
      width: 300px;
  }
}

@media only screen and (max-width:767px){

  .container{width:95%;}
  #viewer {width:95% !important;}
  .sign-wrapper{
      /* width:95% */
      width: 300px;
  }

}

.canvas{
  touch-action: none;
}


.loader {
  display:block;
  margin:auto;
  margin-top:10%;
  margin-bottom:10%;  
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #131414;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

._loading_overlay_spinner{
width:100px  
}

.css-50etie svg circle{
  stroke:black;
}

.alert{
  background-color: black;
  color: white;
}

.submitBtn{
  margin-right:1%
}

.thankYou {
  margin: 15%;
  margin-left: 30%;
  font-size: 2vw;
  font-style: oblique;
  color: brown;
}

.pageNotFound{
  margin: 15%;
  margin-left: 35%;
  font-size: 4vw;
  font-style: oblique;
  color: #de1245;
}

.linkExpire{
  margin: 15%;
  margin-left: 16%;
  font-size: 2vw;
  color: brown;
}

.alreadySigned{
  margin: 15%;
  margin-left: 23%;
  font-size: 1.4vw;
  color: brown;
}


